import { graphql } from "gatsby"
import React, { useEffect } from "react"
import WelcomeBack from "../../../components/account/pages/welcome-back"
import { useTranslationContext } from "../../../context/TranslationContext"

const canonicals = {
  ["en-US"]: "/account/welcome-back",
  ["de-DE"]: "/de/konto/wilkommen-zuruck",
}

const WelcomeBackPage = ({ data }) => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])

  return <WelcomeBack data={data} />
}

export default WelcomeBackPage

export const pageQuery = graphql`
  {
    tileSection: contentfulTileSection(
      title: { eq: "welcome-back-page" }
      node_locale: { eq: "de-DE" }
    ) {
      ...TileSection
    }
  }
`
